@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.cardContainer {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif !important;
}
.cardDiv {
  width: 23rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.6rem;
}
.cardTop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2b9e91;
  color: #ffffff;
  border-radius: 0.6rem 0.6rem 0 0;
  padding: 2.5rem 2rem;
  font-family: "Poppins", sans-serif;
}
.cardTop img {
  border-radius: 50%;
  width: 8rem;
}
.cardTop h1 {
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0.7rem 0 0.5rem 0;
}
.cardTop p {
  font-size: 0.8rem;
  margin: 0%;
  color: #dbdbdb;
}
.cardBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #141414;
  border-radius: 0 0 0.6rem 0.6rem;
  font-family: "Poppins", sans-serif;
  padding: 1.5rem 2.5rem;
}
.cardBottom a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}
.cardBottom label {
  width: 100%;
  border: solid 0.15rem #2b9e91;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  text-decoration: none;
  font-size: 0.9rem;
  background-color: #f6f6f6;
  color: #414141;
}
.cardBottom label:hover {
  background-color: #2b9e91;
  color: #f6f6f6;
  fill: #f6f6f6;
}
.cardBottom img {
  margin-right: 0.5rem;
}
.cardForm {
  padding: 0.3rem;
  border: solid 0.15rem #2b9e91;
  display: flex;
  border-radius: 0.25rem;
  justify-content: space-between;
  margin: 0.5rem 0;
  width: fit-content;
}
.cardForm input {
  outline: none;
  background: transparent;
  border: none;
  color: #f6f6f6;
  padding: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  max-width: 9.57rem;
  text-decoration: none;
}
.cardForm input:hover {
  outline: none;
}
.cardForm button {
  background-color: #414141;
  color: #f6f6f6;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.15rem;
}
.cardBottom button:hover {
  background-color: #2b9e91;
}

@media (max-width: 700px) {
  html {
    font-size: 0.75rem;
  }
  .cardForm input{
      max-width: 8rem;
  }
}
