.updatesContainer{
    display: flex;
    
    justify-content: center;
    background-color: #121212;
    color: #f6f6f6 ;
    padding: .1rem;
}
.updatesDiv{
    
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;

}
.socialIconsDiv{
    /* margin-left: 8rem; */
    text-decoration: none;
    padding: .1rem 0;
    display: flex;
    align-items: center;

}
.socialIconsDiv img{
    width: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.socialIconsDiv a{
    text-decoration: none;
    display: flex;
    color: #f6f6f6;
    margin: 0 .5rem;
    align-content: center;
    padding: .1rem 0;

}
.contactsDiv{
    text-decoration: none;
    color: #f6f6f6;
    display: flex;
    align-items: center;
    

}
.contactsDiv a{
    padding: .2rem;
    display: flex;
    align-content: center;
}
.contactsDiv img{
    width: auto;
    height: 1rem;

}
.contactsDiv a{
    text-decoration: none;
    color: #f6f6f6;
    margin: 0 .5rem;
    display: flex;
    align-items: center;
   
}

@media (max-width:700px){
    .updatesDiv{
        width: 90vw;
    }
}