footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: .7rem;
  position: fixed;
  bottom: 0%;
  width: 100vw;
  font-size: .8rem;
}
hr {
  width: 75%;
  background-color: #3a3a3a;
  opacity: 50%;
}

.footerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1rem;
  /* margin-top: .5rem; */
}
.footerDiv {
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.footerDiv a {
  text-decoration: none;
  color: #3a3a3a;
  font-family: "Questrial",sans-serif;
}
.footerContentContainer{
  display: flex;
  width: 75%;
  flex-direction: row;
  justify-content: space-between;
  align-self: center;
  margin-bottom: 1rem;
}
.footerContentContainer h1{
  font-size: 1rem;
  margin-top: 0%;
  font-weight: normal;
}
.footerContentLeft{
  display: flex;
  flex-direction: column;
  justify-content: left;
}
.footerContentLeft ul{
  list-style: none;
  justify-content: left;
}
.footerContentSecondRow ul{
  /* background-color: #121212; */
  display: flex;
  flex-direction: row;
  /* margin: 0%; */
  padding: .2rem;
  border-radius: 5px;
  justify-content: space-between;

}
.footerIconDiv img{
  width: 1rem;
  height: auto;
  margin: 0 .5rem;
}
.footerContentCenter{
  display: flex;
  flex-direction: column;
}
.footerContentCenter ul{
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0%;
  margin-top: 0%;
  
}
.footerContentCenter li{
  margin: .2rem 0;
  width: 10rem;
  display: flex;
  align-items: center;
}
.footerContentCenter img{
  margin-right: .5rem;
  width: .8rem;
  height: auto;
}
.footerContentCenter a{
  text-decoration: none;
  color: #121212;
}
.footerContentRight{
  width: 12rem;
}
.footerContentRight form{
  display: flex;
  border:2px;
  border-style: solid;
  border-color: #2b9e91;
  border-radius: .2rem;
  min-width: fit-content;
  padding: .2rem;

}
.footerContentRight input{
  border: none;
  background-color: transparent;
  color: #121212;
  opacity: 50%;
  padding: 0 .5rem;
  outline: none;
  max-width: 8rem;
}
.footerContentRight input:active .footerContentRight input:focus{
  border: none;
  outline: none;
}
.footerContentRight button{
  background-color: #2b9e91;
  color: #f6f6f6;
  border: none;
  padding: 0.5rem .8rem;
  border-radius: .2rem;
  cursor: pointer;
  
}

@media (max-width:700px){
  .footerContainer{
    height: auto;
  }
  .footerDiv{
    flex-direction: column;
    width: 95vw;
    justify-content: center;
    align-items: center;
  }
  .footerDiv p{
    margin: 0.15rem 0;
  }
}
