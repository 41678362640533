header{
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: #f6f6f6;
    border-bottom: #3a3a3a;
    border: 1rem;
}
.headerDiv{
    display: flex;
    width: 75%;
    height: 3.5rem;
    justify-content: space-evenly;
    align-items: center;
    
    
}
.headerDiv img{
    margin: 0 3rem;
    width: 6rem;
}
.headerDiv a{
    /* margin:0 1rem; */
    text-decoration: none;
    color: #3a3a3a;
}
.reachUs{
    background-color: #2b9e91;
    color: #f6f6f6 !important;
    padding:0.3rem .5rem;
    border-radius: .2rem;
    border: none;
    /* font-weight: bold; */
    cursor: pointer;
}

